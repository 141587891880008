<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS VIDEOS DE SENSIBILIZACIÓN</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col md="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-photo-video fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaVideosSensibilizacion.length}}</span>
                            <br>
                            <span class="text-muted">Videos registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de videos de sensibilización</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('016-VCM-VID','c')==1" md="6" class="my-2">
                            <b-button block size="md" @click="modalRegistrarVideo = true" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo video
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Categorías registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaVideosSensibilizacion" :fields="campoVideos" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button @click="irAlEnlace(param.item.urlVideo)" class="mr-1 mb-1" size="sm" variant="blue" v-c-tooltip="'Ir al enlace'">
                                        <i class="fas fa-share  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('016-VCM-VID','u')==1" @click="abrirModalDetalleVideos(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('016-VCM-VID','d')==1" @click="eliminarVideoSensibilizacion(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(descripcion)="param">
                                    {{param.item.descripcion | truncate(130) }}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalRegistrarVideo">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo video </span>
            </h6>
            <CButtonClose @click="modalRegistrarVideo = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarVideo)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="url del video" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Url del video:" class="mb-2">
                                <b-form-input type="text" @change="activeURL = true" :state="getValidationState(validationContext)" size="md" v-model="datosVideos.urlVideo" placeholder="Ingrese el url"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosVideos.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="text-center mt-2" v-if="activeURL && datosVideos.urlVideo.includes('v=') && datosVideos.urlVideo.includes('youtube.com') ">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="420" height="315" class="embed-responsive-item" :src="`https://www.youtube.com/embed/${datosVideos.urlVideo.split('v=')[1].substr(0,11)}?autoplay=1&mute=1`">
                        </iframe>
                        </div>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarVideo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalActualizarVideo">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar video</span></h6>
            <CButtonClose @click="modalActualizarVideo = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarVideo)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="url del video" @change="activeURL = true" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Url del video:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarVideo.urlVideo" placeholder="Ingrese el url"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarVideo.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="text-center mt-2" v-if="datosActualizarVideo.urlVideo.includes('v=') && datosActualizarVideo.urlVideo.includes('youtube.com') ">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="420" height="315" class="embed-responsive-item" :src="`https://www.youtube.com/embed/${datosActualizarVideo.urlVideo.split('v=')[1].substr(0,11)}?autoplay=1&mute=1`">
                            </iframe>
                        </div>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarVideo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            activeURL: false,
            disabled: false,
            modalRegistrarVideo: false,
            modalActualizarVideo: false,
            totalRows: 1,
            currentPage: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoVideos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "descripcion",
                    label: "Descripción",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],

            listaVideosSensibilizacion: [],

            datosVideos: {
                idCliente: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                urlVideo: '',
                descripcion: ''
            },
            datosActualizarVideo: {
                idVideoSensibilizacion: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                urlVideo: '',
                descripcion: '',

            }
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        irAlEnlace(url){
            window.open(url)
        },
        abrirModalDetalleVideos(param) {
            let me = this;
            me.datosActualizarVideo.idVideoSensibilizacion = param.item.idVideoSensibilizacion;
            me.datosActualizarVideo.ejercicio = param.item.ejercicio;
            me.datosActualizarVideo.urlVideo = param.item.urlVideo;
            me.datosActualizarVideo.descripcion = param.item.descripcion;
            me.modalActualizarVideo = true;
        },

        registrarVideo() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-video-sensibilizacion",
                    me.datosVideos, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarVideo = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarVideos();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        actualizarVideo() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-video-sensibilizacion",
                    me.datosActualizarVideo, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarVideo = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarVideos();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalRegistrarInforme() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosVideos.urlVideo = '';
            this.datosVideos.descripcion = '';
            // this.datosVideos.idComunidad = null;
            // this.datosVideos.urlDocumento = '';
            // this.datosVideos.nombreDocumento = '';

        },
        resetModalActualizarInforme() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },

        eliminarVideoSensibilizacion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el video?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-video-sensibilizacion", {
                                idVideoSensibilizacion: param.item.idVideoSensibilizacion
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarVideos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        listarVideos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-videos-sensibilizacion", {
                        params: {
                            idCliente: me.datosVideos.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs
                        },
                    }
                )
                .then(function (response) {
                    me.listaVideosSensibilizacion = response.data;
                    me.totalRows = me.listaVideosSensibilizacion.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    watch: {
        modalRegistrarVideo: function (val) {
            if (val == false) {
                this.resetModalRegistrarInforme();
            }
        },
        modalActualizarVideo: function (val) {
            if (val == false) {
                this.resetModalActualizarInforme();
            }
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosVideos.idCliente = this.datosActualizarVideo.idCliente = user.uidClient;
            this.listarVideos();

        }
    }

}
</script>
